<template>
  <div class="body-container" v-if="!isLoading" :style="cssProps">
    <div class="d-flex flex-row pt-2 rounds-header ">
      <strong>{{ translationsFormatter('upcoming_rounds_header_one') }}</strong>&nbsp;
      <div class="light-header-text">{{ translationsFormatter('upcoming_rounds_header_two') }}</div>
    </div>
    <div @click="routeToMyRounds()" class="d-flex flex-column align-items-center text-center pt-5"
         v-if="allRoundsEnteredMessageAlert">
      <img class="col-7" src="../../assets/boots-icon.png">
      <div class="pt-2 col-10">{{ translationsFormatter('upcoming_rounds_entered_all_rounds') }}</div>
    </div>
    <div @click="routeToPlay()" class="d-flex flex-column align-items-center text-center pt-5"
         v-if="noRoundsEnteredMessageAlert">
      <img class="col-7" src="../../assets/red-card-icon.png">
      <div class="pt-2 col-10">{{ translationsFormatter('upcoming_rounds_entered_no_rounds') }}</div>
    </div>
    <ActiveRound class="d-flex flex-column pt-2" v-for="(activeRound, index) in this.activeRounds" :key="index"
                 :activeRound="activeRound"/>
    <div class="mt-4 upcoming-fixtures-header">
      <strong>{{ translationsFormatter('upcoming_fixtures_header_title') }}</strong>
    </div>
    <UpcomingFixture class="d-flex flex-column" v-for="(inactiveRound, index) in this.upcomingFixtures" :key="index"
                     :inactiveRound="inactiveRound"/>
    <div class="mt-4 upcoming-fixtures-header" v-if="brandConfig.brand === 'vc-za-en'">
      <strong>More Games</strong>
      <div @click="upsellBannerClicked('motorsportzone.co.za',27, 122)"
           class="d-flex flex-row col-12 more-games-banner mt-1" style="background-color: #2A2A2A;">
        <div class="d-flex flex-column align-items-center justify-content-center col-2">
          <img class="more-games-banner-img"
               :src="require(`../../brand/${brandConfig.brand}/assets/upsells/icon-motorport upsell.webp`)">
        </div>
        <div class="d-flex flex-column justify-content-start col-7 mx-2 mt-1">
          <div class="d-flex flex-row more-games-header-text text-start">ARE YOU AN F1 ENTHUSIAST?</div>
          <div class="more-games-secondary-text">Motorsport Zone, a hub for trivia, race predictions, & building your
            dream racing team.
          </div>
        </div>
        <div class="d-flex flex-column align-items-center justify-content-center col-3">
          <button class="more-games-banner-btn">{{ motorsportBtnText }}</button>
        </div>
      </div>
      <div @click="upsellBannerClicked('rugby-zone.co.za',28, 124)"
           class="d-flex flex-row col-12 more-games-banner mt-2" style="background-color: #204732;">
        <div class="d-flex flex-column align-items-center justify-content-center col-2">
          <img class="more-games-banner-img"
               :src="require(`../../brand/${brandConfig.brand}/assets/upsells/icon-rugby upsell.png`)">
        </div>
        <div class="d-flex flex-column justify-content-start col-7 mx-2 mt-1">
          <div class="d-flex flex-row more-games-header-text text-start">GET EXCLUSIVE RUGBY CONTENT!</div>
          <div class="more-games-secondary-text">Rugby Zone is the one-stop platform for fantasy, trivia and the latest
            news!
          </div>
        </div>
        <div class="d-flex flex-column align-items-center justify-content-center col-3">
          <button class="more-games-banner-btn">{{ rugbyBtnText }}</button>
        </div>
      </div>
      <div @click="upsellBannerClicked('cricketzone.co.za',26, 125)"
           class="d-flex flex-row col-12 more-games-banner mt-2" style="background-color: #90A04A;">
        <div class="d-flex flex-column align-items-center justify-content-center col-2">
          <img class="more-games-banner-img"
               :src="require(`../../brand/${brandConfig.brand}/assets/upsells/icon-cricket upsell.png`)">
        </div>
        <div class="d-flex flex-column justify-content-start col-7 mx-2 mt-1">
          <div class="d-flex flex-row more-games-header-text text-start">NEVER MISS A CRICKET MATCH!</div>
          <div class="more-games-secondary-text">Keep up with the current news, play trivia, build fantasy teams and
            make match predictions all on Cricket Zone!
          </div>
        </div>
        <div class="d-flex flex-column align-items-center justify-content-center col-3">
          <button class="more-games-banner-btn">{{ cricketBtnText }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapMutations} from "vuex";
import ActiveRound from "@/views/components/ActiveRound";
import UpcomingFixture from "@/views/components/UpcomingFixture";
import GameDataService from "@/services/gameDataService";
import UserDataService from "@/services/userDataService";
import moment from "moment";
import Helpers from "@/helpers/helpers";
import DcbService from "@/services/dcbService";

export default {
  name: "UpcomingRounds",
  components: {ActiveRound, UpcomingFixture},

  data() {
    return {
      allRoundsEnteredMessageAlert: false,
      noRoundsEnteredMessageAlert: false,
      motorsportBtnText: undefined,
      cricketBtnText: undefined,
      rugbyBtnText: undefined,
    }
  },
  computed: {
    ...mapState(['activeRounds', 'isLoading', 'upcomingFixtures', 'user', 'brandConfig']),
    cssProps() {
      return {
        backgroundImage: `url(${require(`@/brand/${this.brandConfig.brand}/assets/background.png`)})`,
      }
    },
  },
  async beforeMount() {
    this.setIsLoading(true);
    await this.formatUpsellBtnText();
    const upcomingFixtures = (await GameDataService.upcomingFixtures()).data;
    const upcomingRounds = (await GameDataService.activeRounds(this.user.msisdn)).data;
    await upcomingRounds.asyncForEach(async (round) => {
      round.selections = {};
      if (round.entered) this.processRoundHasEntries(round)
    })
    this.setRoundsUserCanEnter(upcomingRounds);
    this.setUpcomingFixtures(upcomingFixtures);
    this.checkRoundsEntered();
    this.setIsLoading(false);
  },
  methods: {
    ...mapMutations(['setIsLoading', 'setActiveRounds', 'setUpcomingStoreFixtures']),
    async processRoundHasEntries(round) {
      round.hasFullTeam = true;
      const userEntries = await UserDataService.getUserEntries(round.id, this.user.msisdn);
      const midfielders = []
      userEntries.data.forEach((entry) => {
        if (entry.position === "Forward") this.setPositionEntry(round, "forward", entry);
        if (entry.position === "Midfielder") midfielders.push(entry);
        if (entry.position === "Defence") this.setDefenceEntry(round, entry);
        if (entry.position === "Goalkeeper") this.setPositionEntry(round, "goalkeeper", entry)
      })
      this.setMidFielderEntries(round, midfielders)
    },
    setMidFielderEntries(round, midfielders) {
      this.setPositionEntry(round, 'midfielder1', midfielders[0]);
      this.setPositionEntry(round, 'midfielder2', midfielders[1])
    },
    setPositionEntry(round, position, entry) {
      round.selections[position] = entry
      round.selections[position].teamNames = entry.teamNames
    },
    setDefenceEntry(round, entry) {
      round.selections.defence = entry
      round.selections.defence.teamNames = entry.name.slice(0, 1)
    },

    routeToMyRounds() {
      this.$router.push('/my-rounds');
    },
    routeToPlay() {
      this.$router.push('/upcoming-rounds');
    },
    checkRoundsEntered() {
      this.activeRoundsArrayCount = this.activeRounds.length
      this.counter = 0;

      if (this.$route.name === 'UpcomingRounds') {
        for (const obj of this.activeRounds) {
          if (obj.hasFullTeam === true) this.counter++;
        }
        if (this.counter === this.activeRoundsArrayCount) {
          this.allRoundsEnteredMessageAlert = true;
        }
      }
      if (this.$route.name === 'MyRounds') {
        for (const obj of this.activeRounds) {
          if (obj.hasFullTeam === undefined) this.counter++;
        }
        if (this.counter === this.activeRoundsArrayCount) {
          this.noRoundsEnteredMessageAlert = true;
        }
      }
    },
    setUpcomingFixtures(upcomingFixtures) {
      const fixtures = []
      upcomingFixtures.forEach((upcomingLeagueRound) => {
        let entriesClosingTime = new Date(upcomingLeagueRound.matches[0].startAt);
        let timeNow = moment(new Date());
        let roundEnd = moment(entriesClosingTime)
        let timeDifferenceInDays = moment.duration(timeNow.diff(roundEnd));
        let daysUntilFixture = timeDifferenceInDays._data.days;
        let hoursUntilFixture = -timeDifferenceInDays._data.hours;

        fixtures.push({
          leagueName: upcomingLeagueRound.leagueName,
          leagueImgURL: this.findImageModule(upcomingLeagueRound.league),
          matches: this.setMatchesInUpcomingFixtures(upcomingLeagueRound.matches, upcomingLeagueRound.league),
          timeUntilRoundOpens: {
            days: daysUntilFixture,
            hours: hoursUntilFixture,
          },
        })
      })
      this.setUpcomingStoreFixtures(fixtures)
    },
    setMatchesInUpcomingFixtures(unsortedFixtures) {
      let emptyUpcomingFixtures = [];
      unsortedFixtures.forEach((inActiveMatchFixture) => {
        emptyUpcomingFixtures.push({
          homeTeam: {
            homeTeamName: Helpers.formatTextForAssetNames(inActiveMatchFixture.teamHomeFullName),
            homeBadgeImgURL: Helpers.findTeamImageModule(inActiveMatchFixture.teamHomeFullName)
          },
          awayTeam: {
            awayTeamName: Helpers.formatTextForAssetNames(inActiveMatchFixture.teamAwayFullName),
            awayBadgeImgURL: Helpers.findTeamImageModule(inActiveMatchFixture.teamAwayFullName)
          },
        })
      })
      return emptyUpcomingFixtures;
    },
    findImageModule(league, team) {
      if (!team) return Helpers.findLeagueImageModule(league);
      if (team) return Helpers.findTeamImageModule(team);
    },
    setRoundsUserCanEnter(upcomingRounds) {
      const rounds = []
      upcomingRounds.forEach((activeRoundsDataObject) => {
        let entriesClosingTime = new Date(activeRoundsDataObject.entriesCloseAt);
        let timeNow = moment(new Date());
        let roundEnd = moment(entriesClosingTime);
        let timeDifferenceInDays = moment.duration(timeNow.diff(roundEnd));
        let daysUntilFixture = timeDifferenceInDays._data.days;
        let hoursUntilFixture = -timeDifferenceInDays._data.hours;

        if (entriesClosingTime > new Date()) {
          rounds.push({
            isUpcomingRoundListExpanded: !!activeRoundsDataObject.hasFullTeam,
            hasFullTeam: activeRoundsDataObject.hasFullTeam,
            leagueAbbreviation: activeRoundsDataObject.matches[0].league,
            roundId: activeRoundsDataObject.id,
            leagueName: activeRoundsDataObject.name,
            selections: activeRoundsDataObject.selections,
            matches: this.setMatchesInRounds(activeRoundsDataObject.matches),
            leagueBadgeImgURL: this.findImageModule(activeRoundsDataObject.name),
            timeUntilRoundClose: {
              days: daysUntilFixture,
              hours: hoursUntilFixture,
            },
          })
        }
      })
      this.setActiveRounds(rounds)
    },
    setMatchesInRounds(unsortedUpcomingMatches) {
      let emptyUpcomingMatches = [];
      unsortedUpcomingMatches.forEach((matchFixture) => {
        emptyUpcomingMatches.push({
          homeTeam: {
            homeTeamName: matchFixture.teamHomeFullName,
            homeTeamBadgeImgURL: Helpers.findTeamImageModule(matchFixture.teamHomeFullName),
          },
          awayTeam: {
            awayTeamName: matchFixture.teamAwayFullName,
            awayTeamBadgeImgURL: Helpers.findTeamImageModule(matchFixture.teamAwayFullName),
          },
        })
      })
      return emptyUpcomingMatches;
    },
    async formatUpsellBtnText() {
      const productIds = {
        cricket: 26,
        rugby: 28,
        motorsport: 27,
      };

      const btnTextMap = {
        cricket: 'cricketBtnText',
        rugby: 'rugbyBtnText',
        motorsport: 'motorsportBtnText',
      };

      const promises = Object.entries(productIds).map(async ([sport, id]) => {
        const {data} = await DcbService.getUserViaProductId(this.user.msisdn, id);
        this[btnTextMap[sport]] = data.freePlayService ? 'learn more' : 'play now';
      });

      await Promise.all(promises);
    },
    async upsellBannerClicked(domain, productId, campaignId) {
      const {freePlayService} = (await DcbService.getUserViaProductId(this.user.msisdn, productId)).data;
      const {encryptedMsisdn} = await DcbService.encryptUserMsisdn(this.user.msisdn)
      let url = `https://${domain}/?e-msisdn=${encryptedMsisdn}`;
      if (freePlayService) {
        url += `&campaignId=${campaignId}`;
      }
      window.open(url, '_self');
    },
  },
  watch: {
    $route(to, from) {
      if (this.$route.path === '/my-rounds') {
        this.checkRoundsEntered();
        this.allRoundsEnteredMessageAlert = false
      }
      if (this.$route.path === '/upcoming-rounds') {
        this.checkRoundsEntered();
        this.noRoundsEnteredMessageAlert = false
      }
    },
  }
}
</script>

<style scoped>
.body-container {
  padding-bottom: 8vh;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
}

.body-container::-webkit-scrollbar {
  display: none;
}

.rounds-header, .upcoming-fixtures-header {
  font-size: 14px;
}

.light-header-text {
  color: #939393;
}

.more-games-banner {
  height: 55px;
  border-radius: 10px;
  color: #FFFFFF;
  border: 0;
}

.more-games-header-text {
  font-size: 11px;
  font-weight: 700;
}

.more-games-secondary-text {
  font-size: 9px;
  color: #C3C3C3;
  line-height: 1.1;
}

.more-games-banner-btn {
  color: #FFFFFF;
  background-color: #EF151C;
  font-size: 10px;
  border-radius: 5px;
  height: 22px;
  width: 65px;
  border: 0;
  margin-right: 35px;
  font-weight: 600;
}

.more-games-banner-img {
  width: 42px;
  margin-left: 4px;
}
</style>
